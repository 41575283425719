<template>
  <div class="d-flex flex-column">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-1">
        <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
          <b-card
            header="Primary"
            header-bg-variant="secondary"
            header-text-variant="white"
            v-if="requestVisitDays"
          >
            <template #header>
              <h6 class="mb-0">{{ $t("Change Visit Day") }}</h6>
            </template>
            <b-card-text class="py-3 px-3">
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    {{ $t("Set New Visit Day") }}
                    <span class="required ml-2">*</span> :
                  </h6>
                  <v-autocomplete
                    v-model="requestVisitDays.visitdays"
                    :items="weekdays"
                    item-text="label"
                    item-value="value"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    {{ $t("Set Week") }}
                    <span class="required ml-2">*</span> :
                  </h6>
                  <v-autocomplete
                    v-model="requestVisitDays.settedWeeks"
                    :items="weeks"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    {{ $t("Select Brands") }}
                    <span class="required ml-2">*</span> :
                  </h6>
                  <v-autocomplete
                    v-model="requestVisitDays.brands"
                    :items="worksessionPosBrands"
                    item-text="name"
                    item-value="id"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    {{ $t("Please enter reason") }}
                    <span class="required ml-2">*</span> :
                  </h6>
                  <v-textarea
                    v-model="requestVisitDays.gpvComments"
                    required
                    :rules="requiredRules"
                  ></v-textarea>
                </v-col>
              </v-row>
            </b-card-text>
            <template #footer>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="d-flex justify-content-center"
                >
                  <v-btn
                    color="success"
                    dark
                    class="mx-1 my-2"
                    :loading="isSaving"
                    @click="onSaveClick(requestVisitDays)"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </b-card>

          <b-alert v-if="!requestVisitDays" show variant="info">
            <b-icon
              icon="arrow-counterclockwise"
              animation="spin-reverse"
            ></b-icon>
            cargando datos...
          </b-alert>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { logInfo, logError } from "@/utils";

export default {
  name: "changeVisitDay",
  props: ["id", "worksessionPosId"],
  components: {},
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", [
      "currentPosData",
      "currentWorksessionData",
      "worksessionPosBrands",
      "currentRequestVisitDays",
      "currentVisitDays",
    ]),
  },

  data: function() {
    return {
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      requiredRulesArray: [(v) => (!!v && v.length > 0) || "Required"],
      isSaving: false,
      requestVisitDays: null,
      weekCount: 4,
      weekdays: [],
      weeks: [1, 2, 3, 4],
    };
  },
  methods: {
    async onSaveClick(requestVisitDays) {
      if (this.$refs.form.validate()) {
        let body = requestVisitDays;
        if (!body.date) {
          body.date = new Date().toISOString().slice(0, 10);
        }
        this.isSaving = true;
        if (body.id) {
          await ApiService.put(
            `worksession/saverequestvisitdays/${body.id}`,
            body
          );
        } else {
          await ApiService.post(`worksession/saverequestvisitdays`, body);
        }
        this.isSaving = false;
        logInfo("Éxito");
        this.init();
      }
    },
    createSelectedModel(currentRequestVisitDays, currentVisitDays) {
      let model = {
        id: _.get(currentRequestVisitDays, "id"),
        userId: _.get(currentRequestVisitDays, "userId"),
        routeId: _.get(currentRequestVisitDays, "routeId"),
        posId: _.get(currentRequestVisitDays, "posId"),
        gpvComments: _.get(currentRequestVisitDays, "gpvComments"),
        brands: _.get(currentRequestVisitDays, "brands"),
        date: _.get(currentRequestVisitDays, "date"),
        visitdays: _.get(currentRequestVisitDays, "visitdays"),
        settedWeeks: _.get(currentRequestVisitDays, "settedWeeks"),
      };
      if (!currentRequestVisitDays) {
        model.visitdays = _.get(currentVisitDays, "visitdays");
        model.settedWeeks = _.get(currentVisitDays, "settedWeeks");
      }
      if (model.brands && model.brands.length > 0) {
        model.brands = model.brands.map((el) => el.id);
      } else {
        model.brands = null;
      }
      if (!model.userId) model.userId = this.user.id;
      if (!model.routeId) model.routeId = this.currentWorksessionData.routeId;
      if (!model.posId) model.posId = this.currentWorksessionData.posId;
      return model;
    },
    async init() {
      let { weekdays, weekCount } = await ApiService.post(
        `worksession/getbasedata?isFlag=weekdays`
      );
      this.weekdays = weekdays;
      this.weekCount = weekCount;
      this.weeks = [];
      for (let i = 1; i <= weekCount; i++) {
        this.weeks.push(i);
      }
      await this.$store.dispatch("myroute/getWorksessionPos", {
        worksessionPosId: this.worksessionPosId,
      });
      console.log("currentWorksessionData - ", this.currentWorksessionData);
      console.log("worksessionPosBrands - ", this.worksessionPosBrands);
      await this.$store.dispatch("myroute/getCurrentRequestVisitDays", {
        userId: this.user.id,
        routeId: this.currentWorksessionData.routeId,
        posId: this.currentWorksessionData.posId,
      });
      console.log("currentRequestVisitDays - ", this.currentRequestVisitDays);
      console.log("currentVisitdays - ", this.currentVisitDays);
      this.requestVisitDays = this.createSelectedModel(
        this.currentRequestVisitDays,
        this.currentVisitDays
      );
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>

<style></style>
